import _get from "lodash/get";

import {mask} from 'vue-the-mask';

import axios from 'axios';

const MONTH_POSITION = 0;
const YEAR_POSITION = 1;

export default {
    directives: {mask},
    props: {
        currentStep: {
            type: String,
            default: ''
        },
        billingAccountNumber: {
            type: String,
            default: ''
        },
        balance: {
            type: String,
            default: ''
        },
        email: {
            type: String,
            default: ''
        },
        isCheckingAllowed: {
            type: Boolean,
            default: true
        },
        paymentAmountOld: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            modalId: "card-quick-pay-bill-detail",
            modalTitle: "quickPayBillDetail.modalTitle",
            alertData: {status: false},
            dateMasked: "##/##",
            paymentAmount: 0,
            emailAddress: null,
            paymentType: 'credit',
            cardNumberRawForInternal: '', // must pass a luhn mod10 check - use https://developer.authorize.net/hello_world/testing_guide/ for testing
            cardData: {
                cardNumber: null, // must pass a luhn mod10 check - use https://developer.authorize.net/hello_world/testing_guide/ for testing
                exp: null, // month (1 or 2 digits) - year (2 - 4 digits)
                cardCode: null, // 3 for VISA, Master, Discover
                fullName: null, // optional, max 64 chars. CANNOT BE NULL. MUST USE EMPTY STRING!
                zipCode: null,
                cardCompanyId: null,
            },
            bankData: {
                accountNumber: null, // max 17 digits
                routingNumber: null, // 9 digits
                nameOnAccount: null, // max 22 chars
                // accountType: 'checking' // checking, savings, businessChecking
            },
            opaqueData: {
                dataDescriptor: null,
                dataValue: null
            },
            rules: {
                accountNumber: this.$rules.accountNo(this.$i),
                routingNumber: this.$rules.routingNo(this.$i),
                itemRequired: this.$rules.itemRequiredRules(this.$i),
                zipCode: this.$rules.zipCode(this.$i),
                cardCode: this.$rules.cardCode(this.$i),
                exp: this.$rules.exp(this.$i),
                creditCard: this.$rules.creditCard(this.$i),
                cardHolder: this.$rules.cardHolder(this.$i),
                paymentAmountRule: this.$rules.paymentAmount(this.$i),
                emailAddressRule: []
            },
            errMsg: null,
            userClickedMakePaymentButton: false,
            loadingMessage: null,
            cardCompanyIconObj: {
                1: 'fab fa-cc-visa',
                2: 'fab fa-cc-mastercard',
                3: 'fab fa-cc-discover',
            },
            hiddenSendEmailCheckboxValue: false,
        };
    },
    async mounted() {
        let acceptScript = document.createElement('script');

        let result = await axios.get(`/api/QuickPayBill/AcceptJsConfigs`);
        let acceptUrl = result.data.data.acceptUrl;
        this.authData = result.data.data.authData;

        acceptScript.setAttribute('src', acceptUrl);
        document.head.appendChild(acceptScript);

        // non related to accept.js
        this.initializeFields();
    },
    filters: {
        formatBalance: function (balance) {
            return new Intl.NumberFormat("us-US", {style: "currency", currency: "USD"}).format(balance);
        },
    },
    computed: {
        isReady: function () {
            return this.currentStep === 'detail';
        },
        curBalance: function () {
            // todo get it
            return `$  ${this.balance ? this.balance : 0}`;
            // let cbp = this.$store.getters.customerBillPackageByAccountNum(this.billingGroupNo);
            // return _get(cbp, 'balanceInfo.totalBalance');
        },
        cardCompanyIcon: function () {
            let cardCompanyId = _get(this, 'cardData.cardCompanyId');
            if (!cardCompanyId) {
                return Object.values(this.cardCompanyIconObj);
            } else {
                return [this.cardCompanyIconObj[cardCompanyId]];
            }
        },
        showRemainingCreditCardFields: function () {
            let ccNo = _get(this, 'cardData.cardNumber');
            return !!(ccNo && ccNo.length > 15);
        },
        showCreditCardHolderNameInputField: function () {
            let zip = _get(this, 'cardData.zipCode');
            return !!(zip && zip.length === 5);
        },
        showRemainingBankingFields: function () {
            let routing = _get(this, 'bankData.routingNumber', '');
            return !!(routing && routing.length === 9);
        },
        cardNumberRawInput: {
            get: function () {
                let l = this.cardNumberRawForInternal.length;
                let output = '';
                for (let i = 0; i < l; i++) {
                    let char = this.cardNumberRawForInternal[i];
                    if (char === '-') char = '';
                    output += char;
                    if ((i + 1) % 4 === 0) {
                        output += ' ';
                    }
                }
                return output.trim();
            },
            set: function (newValue) {
                this.cardData.cardCompanyId = null;
                if (!newValue) {
                    this.cardData.cardNumber = null;
                    this.cardNumberRawForInternal = '';
                } else {
                    let val = newValue.length > 4 ? newValue.split(" ").join("") : newValue;
                    this.cardData.cardNumber = val;
                    this.cardNumberRawForInternal = val;

                    if (val.length === 16) {
                        if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(val)) {
                            this.cardData.cardCompanyId = 2    //master
                        } else if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(val)) {
                            this.cardData.cardCompanyId = 1    //visa
                        } else if (/^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/.test(val)) {
                            this.cardData.cardCompanyId = 3  //discover
                        } else {
                            this.cardData.cardCompanyId = null;
                            this.showAlert(true, 'error', 'modalAddNewPaymentProfile.errCreditCardType');
                        }
                    } else {
                        this.cardData.cardCompanyId = null;
                    }
                }
            }
        },
        emailAddressRule: function(){
          return this.sendEmailConfirmation ?   this.$rules.emailRules(this.$i) : [];
        },
        sendEmailConfirmation: {
            get: function () {
                return !!(this.hiddenSendEmailCheckboxValue || this.emailAddress && this.emailAddress.trim().length);
            },
            set: function (newValue) {
                this.hiddenSendEmailCheckboxValue = !!newValue;
                this.emailAddress = null;
            }
        }
    },
    methods: {
        initializeFields: function () {
            for (let propField of ['paymentAmountOld']) {
                let newField = propField.slice(0, -3);
                this[newField] = this[propField] ? this[propField] : '';
            }
            this.emailAddress = this.email;
        },
        showHideLoadingBar: function (loadingMessage) {
            this.componentReady = !Boolean(loadingMessage);
            this.loadingMessage = loadingMessage ? this.$i.s.quickPayBillDetail[loadingMessage] : null;
        },
        resetNonInputFields: function () {
            this.paymentType = 'credit';
            this.errorMsg = null;
        },
        resetForm(type) {
            let clearObj = type === 'credit' ? 'cardData' : 'bankData';
            for (let field of Object.keys(this[clearObj])) {
                field = null;
            }
            if (type === 'credit') {
                this.cardNumberRawForInternal = '';
            }
        },
        initialState: function (status) {
            this.$refs.quickPayBillDetail.reset();
            this.resetForm('credit');
            this.resetForm('bank');
            this.resetNonInputFields();
        },
        cancel: function () {
            this.errorMsg = null;
            this.$emit('next', {
                emitType: 'cancel',
                nextStep: 'initial'
            })
        },
        confirmQuickPayBillWithoutLogin: async function () {
            if (!this.$refs.quickPayBillDetail.validate()) return;

            let alertObj = {
                alertStatus: true,
                alertType: 'error',
                alertMsg: ''
            };

            if (this.paymentType === 'bank' && !this.isCheckingAllowed) {
                alertObj.alertMsg = this.errorMsg;
                return this.$emit('error', 'quickPayBillDetail.errCheckingNotAllowed');
            }

            let lastFour = this.paymentType === 'credit' ? _get(this, 'cardData.cardNumber') : _get(this, 'bankData.accountNumber');

            // Get payment Information to display on Confirmation Page
            this.getOpaqueDataByPostingToAuthNet();

            if (this.errorMsg) {
                alertObj.alertMsg = this.errorMsg;
                return this.$emit('error', alertObj);
            }

            this.$emit('next', {
                emitType: 'next',
                nextStep: 'confirm',
                paymentAmount: parseFloat(this.paymentAmount),
                paymentType: this.paymentType,
                opaqueData: this.opaqueData,
                lastFour: lastFour ? lastFour.slice(-4) : '',
                email: this.emailAddress,
            });
        },
        getOpaqueDataByPostingToAuthNet: function () {
            if (!this.$refs.quickPayBillDetail.validate()) return;
            // console.log('posting to authnet');

            let secureData = {
                authData: this.authData
            };

            if (this.paymentType === 'credit') {
                secureData.cardData = {
                    fullName: this.cardData.fullName,
                    cardNumber: this.cardData.cardNumber,
                    month: this.cardData.exp.split('/')[MONTH_POSITION],
                    year: this.cardData.exp.split('/')[YEAR_POSITION],
                    cardCode: this.cardData.cardCode,
                    zipCode: this.cardData.zipCode,
                };
            } else {
                secureData.bankData = {
                    accountType: 'checking',
                    accountNumber: this.bankData.accountNumber,
                    routingNumber: this.bankData.routingNumber,
                    nameOnAccount: this.bankData.nameOnAccount
                };
            }

            Accept.dispatchData(secureData, this.responseHandler);

        },
        async responseHandler(response) {
            if (response.messages.resultCode === "Error") {
                let i = 0;
                while (i < response.messages.message.length) {
                    console.log(
                        response.messages.message[i].code + ": " +
                        response.messages.message[i].text
                    );
                    i = i + 1;
                }
                let error = _get(response, 'messages.message[0].text');
                await this.$datadog.dropLog(`AuthNet returned error: ${error}`, 'card-pay-bill-detail');
                this.errorMsg = error;
            } else {
                // console.log('updating payment form: removing sensitive datas');
                this.opaqueData.dataDescriptor = response.opaqueData.dataDescriptor;
                this.opaqueData.dataValue = response.opaqueData.dataValue;
                this.errorMsg = null;

            }
        },

    },
};
