export default {
    login: {
        headerText:"Welcome to the APG&E Customer MyAccount Portal. You can manage your account and view your usage here!",
        loginHelp:"Need Help Logging In?",
        username:"Email Address or User Name",
        password:"Password",
        cancel:"Cancel",
        clear:"Clear",
        login:"Login",
        notACustomer:"Not an APG&E Customer?",
        click:"Click",
        here:"here",
        registerYourAccount:"Register Your Account with APG&E",
        cardFooter:"to learn about our great rates and first-class customer service.",
        errLogin:"Incorrect username or password.",
        errNotVerified:"Your email is not verified.",
        successLogin:"Login success. Redirecting to homepage.",
        thankYou:"Thank you!",
        ok:"OK",
        verifyEmail:"Click here to resend your email verification.",
    },
    manualSignup: {
        signupNoCode:"Thank you for choosing APG&E as your energy provider. On this APG&E Customer MyAccount Portal, you can manage your account and view your usage here. Please type in following information. You can find Billing Account Number on your monthly bill.",
        signupWithCode:"Welcome to the APG&E Customer MyAccount Portal. You can manage your account and view your usage here. Please type in the following information.",
        email:"Email Address",
        phone:"Phone Number",
        newPassword:"New Password",
        confirmPassword:"Confirm Password",
        billPkgList:"Billing Account Numbers",
        cancel:"Cancel",
        signup:"SignUp",
        errItemRequired:"This field is required.",
        errSignupCode:"Looks like the signup url you were given is not working. Please try again and if the issue persists, please call our Customer Care Team at 1-877-544-4857 or email customer@apge.com.",
        errSignup:"Something went wrong while signing up. Please try again and if the issue persists, please call our Customer Care Team at 1-877-544-4857 or email customer@apge.com.",
        errMissingCbp:"There is an issue with your billing account numbers.  Please verify the numbers and try again.  If the issue persists, please call our Customer Care Team at 1-877-544-4857 or email customer@apge.com.",
        errDuplicateCbps1: "Billing Account Number {{billingAccountNumber}} is already associated with email address {{emailAddress}}. ",
        errDuplicateCbps2: "If you can't login, please try resetting your password from Reset Password button. If the issue persists or you need additional help, please call our Customer Care Team at 1-877-544-4857 or email customer@apge.com.",
        errDigits7or8:"7 or 8 digits",
        errDigits2or3:"2 or 3 digits",
        errCantDelete:"Can't delete this item. There must be at least one Billing Account Number .",
        successSignup:"Signup complete! Redirecting you to Login page",
        successEmailSent:"Please check your email. We sent you an email to verify your email address. If your email doesn't arrive, check your SPAM folder. ",
    },
    resetPassword: {
        headerText:"Please choose a new password:",
        passwordRule:"Password must be at least 8 characters, include a number, a lowercase letter, and an uppercase letter.",
        newPassword:"New Password",
        confirmPassword:"Confirm Password",
        changePassword:"Change Password",
        errPasswordReset:"Password update failed. Please try again.",
        errPasswordConfirmRequired:"Confirm Password is required.",
        errPasswordMatch:"New Password and Confirm Password do not match.",
        errLinkExpired:"The link provided to reset your password is either invalid or has expired.",
        clickHere:"Click here to request a new one.",
        successPasswordReset:"Password was updated successfully.",
    },
    resetPasswordEmailSent: {
        headerText:"An email with password reset instructions has been sent to the email address provided.",
        linkLimits:"For your security, this link will be active for 1 hour. If your email doesn't arrive, check your SPAM folder. ",
        returnToLogin:"Return to Login"
    },
    resetPasswordRequest: {
        headerText:"Please enter the email address that you used to sign up.",
        cancel:"Cancel",
        submit:"Submit",
        email:"Email Address",
        resetPassword:"Reset Password",
        footerText:"Don't remember your account information?  Call our Customer Care Team at 1-877-544-4857 or email customer@apge.com.",
    },
    verifyEmail: {
        successEmailSent:"Please check your email. We sent you an email to verify your email address. If your email doesn't arrive, check your SPAM folder.",
        successVerify:"Email verified successfully. Redirecting you to Login page.",
        errVerify:"Sorry! Your verification code seems invalid. Please request a new one. Redirecting you to Login page.",
        errSomethingWrong:"Something went wrong. If the issue persists, please call our Customer Care Team at 1-877-544-4857 or email customer@apge.com.",
        loading:"Loading...",
        failedVerify:"Email verification failed. Please call our Customer Care Team at 1-877-544-4857 or email customer@apge.com for help.",
        successVerifyChange:"Email verification success. Please refresh your log in page for the update.",
    },
    ruleErrors: {
        errEmailRequired:"Email Address is required.",
        errEmailInvalid:"Invalid email address.",
        errPasswordRequired:"Password is required.",
        errPasswordRule:"Must be at least 8 characters, include a number, a lowercase letter, and an uppercase letter.",
        errPasswordConfirmRequired:"Confirm Password is required.",
        errPasswordMatch:"New Password and Confirm Password do not match.",
        errPhoneRequired:"Phone Number is required.",
        errPhoneInvalid:"Invalid phone number.",
        errItemRequired:"This field is required.",
        errNumberOnly:"Invalid Numbers",
        errDigits7or8:"7 or 8 digits",
        errDigits2or3:"2 or 3 digits",
        errDigits5:"5 digits",
        err16digits:"16 digits",
        err5digits:"5 digits",
        errCardCode:"3 digits",
        errRouting:"9 digits",
        errAccNo:"4 to 17 digits",
        errFormat:"Invalid Format",
        errMonth:"Invalid Month",
        errYear:"Invalid Year",
        err22Length:"Maximum 22 characters",
        err64Length:"Maximum 64 characters",
        errRequired:"Required",
        errInvalidAmount:"Invalid Amount",
        errInvalidExpiration:"Invalid Expiration",
        errGreaterThanZero:"Amount must be greater than zero",
    },
    quickPayBillInitial: {
        headerTitle:"Quick Bill Pay",
        headerText:"Please enter your Billing Account Number and service zip code to make a payment.",
        zipCode:"Service Zip Code",
        checkAmountDue:"Check Amount Due",
        billingAccountNumber:"Billing Account Number ",
        goToLoginPage:"Go to Login Page",
        billingAccountNumberLocation:"Billing Account # is located on the first page of your monthly bill at the top right corner",
        cancel:"Cancel",
        accountUnderPOR:"APG&E is unable to accept your payment directly. All payments should be made directly with your utility company. Please contact your utility company for payment options.",
        errNoBillingAccountNumberFound:"We could not find the Billing Account Number with the Zip Code.  Please verify the numbers and try again.  If the issue persists, please call our Customer Care Team at 1-877-544-4857 or email customer@apge.com.",
        errNoAmountDueFound:"We could not retrieve Amount Due detail from the Billing Account Number. Please refresh the screen and try again.  If the issue persists, please call our Customer Care Team at 1-877-544-4857 or email customer@apge.com.",
        errReCaptchaNotChecked:"Please check the checkbox",
        errReCaptchaExpired:"ReCAPTCHA session is expired. Please check the checkbox again.",
        errReCaptchaError:"ReCAPTCHA session is errored. Please refresh and try again.",
        errPleaseTryAgain:"Please try again. If the issue persists, please call our Customer Care Team at 1-877-544-4857 or email customer@apge.com.",
        processingPayment:"Processing your payment",
    },
    quickPayBillDetail: {
        headerText:"Please enter your payment information to continue.",
        billingAccountNumber:"Billing Account Number: ",
        amountDue:"Amount Due: ",
        chooseAmount:"Payment Amount: ",
        cardNumber:"Card Number",
        exp:"Expiration",
        cardCode:"CVV",
        zipcode:"Zip Code",
        continue:"Continue",
        fullName:"Cardholder Name",
        bankAccountNumber:"Account Number",
        bankRoutingNumber:"Routing Number (9 digits)",
        businessCheckingCheckbox: "Business Checking Account",
        errInvalidRoutingNo:"Routing Number is invalid",
        errCheckingNotAllowed:"Checking is not allowed for this user",
        nameOnAccount:"Name on Account",
        bankAccount:"Bank Account",
        creditCard:"Credit Card",
        hintExp:"MM/YY",
        errCreditCardType:"Invalid Credit Card Type. We accept VISA, MasterCard and Discover.",
        sendConfirmationEmail:"Email Payment Confirmation(Optional)",
        emailAddress:"Email Address (Optional)",
    },
    quickPayBillConfirm:{
        headerText:"Please confirm below payment detail: ",
        paymentAmount:"Payment Amount: ",
        payFrom:"Pay From: ",
        creditCardEnding:"Credit/Debit Card ending",
        bankAccountEnding:"Bank Account ending",
        payToBillingAccount:"Pay to Billing Account: " ,
        sendConfirmationEmailTo:"Payment Confirmation email will be sent to: " ,
        yes:"Pay",
        cancel:"Cancel",
    },
    quickPayBillResult:{
        billingAccountNumber:"Billing Account Number: ",
        paymentConfirmationNumber:"Payment Confirmation Number: ",
        successArrangePymt:"We have scheduled your payment! It will be reflected to your account once your transaction is completed.",
        successArrangePymtEmailWillBeSent:"Confirmation email will be sent to {{emailAddress}}.",
        successArrangePymtThankYou:"Thank you for your payment.",
        errPaymentProcess:"There was an error while processing your payment. Please try again. If the issue persists, please call our Customer Care Team at 1-877-544-4857 or email customer@apge.com.",
        errPleaseTryAgain:"Please try again. If the issue persists, please call our Customer Care Team at 1-877-544-4857 or email customer@apge.com.",
        errDuplicateTransaction: "It looks like you already submitted a payment for that amount. If this is intentional, please wait 2 minutes and try again. If the issue persists, please call our Customer Care Team at 1-877-544-4857 or email customer@apge.com.",
        makeAnotherPayment:"Pay Another",
        done:"I am finished",
    },
    maintenance:{
        message:"Thank you for visiting! We are currently offline for a little scheduled maintenance.  We should have everything back up by 4 PM CST on Sunday.",
        email:"Email",
        phone:"Phone",
        hours:"Hours (CST)",
        ourEmail:"customer@apge.com",
        ourPhone:"1.877.544.4857",
        ourHoursWeekday:"8:00AM - 7:00PM",
        ourHoursFriday:"8:00AM - 5:00PM",
        ourHoursSaturday:"9:00AM - 1:00PM",
        ourHoursSunday:"Closed ",
    },
};

