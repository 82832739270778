import axios from 'axios';
const SUCCESS = 'success';
import cardTemplate from '../card-template/template.vue';

export default {
    components: {
        'cardTemplate': cardTemplate
    },
    data() {
        return {
            textToDisplay: null,
            dataToAxios: null,
        };
    },
    mounted() {
        this.textToDisplay = this.$i.s.verifyEmail.loading;
        this.matchEmail(this.$route.query.email, this.$route.query.verificationCode);
    },

    methods: {
        matchEmail: async function (queryEmail, queryVCode) {
            this.dataToAxios = {
                emailAddress: queryEmail,
                emailVerificationCode: queryVCode
            };

            try {
                const response = await axios.put('/api/UserProfile/VerifyEmailAddress', this.dataToAxios);
                this.textToDisplay = response.data.data.isEmailVerified? this.$i.s.verifyEmail.successVerify : this.$i.s.verifyEmail.errVerify ;
                setTimeout(() => {
                    this.$router.replace({name: 'login'});
                }, 3000);

            } catch (err) {
                this.textToDisplay =  this.$i.s.verifyEmail.errVerify ;
                setTimeout(() => {
                    this.$router.replace({name: 'login'});
                }, 5000);
            }
        }
    }
};
