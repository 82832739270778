import axios from 'axios';
import cardTemplate from '../card-template/template.vue';
import get from 'lodash/get';

const EMAIL_NOT_VERIFIED_MESSAGE = 'Email is not verified';
const FIRST_INDEX = 1;
export default {
    components:{
        'cardTemplate': cardTemplate
    },
    data() {
        return {
            formData:{
                email:null,
                password: null,
            },
            rules:{
                emailRules:this.$rules.itemRequiredRules(this.$i),
                passwordRules:this.$rules.itemRequiredRules(this.$i)
            },
            alertData:{
                alertStatus:false,
                alertType:'error',
                alertMsg:null,
            },
            dataToAxios:null,
            isFormValid:false,
            resendToThisEmail:null,
            enrollUrl:null,
        };
    },
    computed:{
        getLinkPerLang: function(){
            let isEnglish = this.$i.language === 'en';
            // if(isEnglish) {
            //     return 'https://enroll.apge.com/'
            // } else {
            //     return 'https://enroll.apge.com/?displayLanguage=spanish'
            // }
            if(isEnglish) {
                return this.enrollUrl
            } else {
                return this.enrollUrl + '?displayLanguage=spanish'
            }
        }
    },
    async mounted() {
        await this.getEnrollmentRoute();
    },
    methods: {
        async getEnrollmentRoute(){
            this.enrollUrl = (await axios.get('/api/enrollUrl')).data.data.enrollUrl;
        },
        showAlert(alertStatus, alertType, alertMsg){
            this.alertData.alertStatus = alertStatus;
            this.alertData.alertType = alertType;
            this.alertData.alertMsg = alertMsg;
            this.$refs.loginForm.resetValidation();
        },
        clearForm: function(){

            this.$refs.loginForm.reset();
        },
        sendVerifyEmail: function(){
            if(!this.resendToThisEmail) this.showAlert(true, 'error', "login.errLogin");
            return axios.post('/api/UserProfile/SendVerificationEmail', {emailAddress: this.resendToThisEmail})
                .then(res => {
                    this.resendToThisEmail = null;
                    if(res.data.data.emailSent) {
                       return this.showAlert(true, 'info', 'verifyEmail.successEmailSent');
                    } else {
                        return this.showAlert(true, 'error', 'verifyEmail.errSomethingWrong');
                    }
                })
                .catch(err => {
                    this.resendToThisEmail = null;
                    this.showAlert(true,'error', 'verifyEmail.errSomethingWrong')
                })
        },
        login: function () {
            if(!this.$refs.loginForm.validate())return;

            return axios.put('/api/UserProfile/Login/', {
                username: this.formData.email,
                password: this.formData.password
            })
                .then((loginInfo)=>{
                    if(!loginInfo.data.data.userProfile_id){
                        this.$refs.loginForm.reset();
                        this.showAlert(true, 'error', "login.errLogin");
                        return;
                    }

                    if(loginInfo.data.data.apgeAdmin){
                        window.location.replace("/admin")
                    } else {
                        window.location.replace("/secure")
                    }
                })

                .catch(err=>{
                    let errMsg = get(err, 'response.data.message');
                    if (errMsg.includes(EMAIL_NOT_VERIFIED_MESSAGE)) {
                        this.resendToThisEmail = this.formData.email;
                        this.showAlert(true, 'error', "login.errNotVerified");
                    } else {
                        this.showAlert(true, 'error', "login.errLogin");
                    }
                })
        }
    }
};
