import axios from 'axios';
import cardTemplate from '../card-template/template.vue';
import quickPayBillInitial from '../card-quick-pay-bill-initial/template.vue';
import quickPayBillDetail from '../card-quick-pay-bill-detail/template.vue';
import quickPayBillConfirm from '../card-quick-pay-bill-confirm/template.vue';
import quickPayBillResult from '../card-quick-pay-bill-result/template.vue';

import {get as _get} from 'lodash';
const CLEAR_FIELDS = {
    initial: ['customerTkn', 'billingPkgTkn', 'zipCode', 'balance'],
    detail: ['lastFour', 'paymentAmount', 'opaqueData', 'email'],
    result: ['paymentAmount', 'opaqueData', 'confirmationId', 'messageProperty', 'authNetMessage']
}

export default {
    components: {
        'card-template': cardTemplate,
        'pay-bill-initial': quickPayBillInitial,
        'pay-bill-detail': quickPayBillDetail,
        'pay-bill-confirm': quickPayBillConfirm,
        'pay-bill-result': quickPayBillResult,
    },
    data() {
        return {
            alertData: {alertStatus: false},
            isProcessingPayment: false, // display loader while processing payment
            currentStep: 'initial',
            isCheckingAllowed: true, // initial <-- main --> detail
            isPOR: false, // initial <-- main --> detail
            paymentType: 'credit',      // detail <-- main --> confirm

            customerTkn: null,      // initial <-- main --> detail
            billingPkgTkn: null,    // initial <-- main --> detail
            zipCode: null,          // initial <-- main --> detail
            balance: null,          // initial <-- main --> detail
            email: null,          // initial <-- main --> detail
            opaqueData: null,    // main <-> detail

            lastFour: null,         // detail <-- main --> confirm
            paymentAmount: null,    // detail <-- main --> confirm

            paymentResultMessageType: 'info',
            confirmationId: null, //  main --> result
            messageProperty: null, //  main --> result
            authNetMessage: null, //  main --> result
        }
    },
    computed: {
        initialStep: function () {
            return this.currentStep === 'initial';
        },
        headerTextByStep: function () {
            let capitalizedStepName = this.currentStep.charAt(0).toUpperCase() + this.currentStep.toLowerCase().slice(1);
            let foundStrings = this.$i.s[`quickPayBill${capitalizedStepName}`].headerText || '';
            return `${foundStrings}`;
        },
        billingAccountNumber: function () {
            if (this.customerTkn && this.billingPkgTkn) {
                return `${this.customerTkn}-${this.billingPkgTkn}`;
            }
            return '';
        },
        payFrom: function () {
            if (this.currentStep === 'confirm' && this.lastFour) {
                return `${this.paymentType === 'credit' ? `${this.$i.s.quickPayBillConfirm.creditCardEnding}` : `${this.$i.s.quickPayBillConfirm.bankAccountEnding}`} ${this.lastFour}`;
            }
            return '';
        }
    },
    methods: {
        showAlert: function (args) {
            let {alertStatus, alertType, alertMsg} = args;
            this.alertData = {alertStatus, alertType, alertMsg};
        },
        initializeAllDataFields: function () {
            for (let step of ['initial', 'detail', 'result']) {
                this.initializeDataFieldsForStep(step);
            }
            this.currentStep = 'initial';
            this.paymentResultMessageType = 'info';
            this.isCheckingAllowed = true;
            this.isPOR = false;
        },
        initializeDataFieldsForStep: function (step) {
            if (CLEAR_FIELDS.hasOwnProperty(step)) {
                for (let item of CLEAR_FIELDS[step]) {
                    this[item] = null;
                }
            }
            if (step === 'detail') {
                this.paymentType = 'credit';
            }
            this.alertData = {alertStatus: false};
        },
        setPassedInFields: function (args) {
            for (let item in args) {
                if (args.hasOwnProperty(item) && this.hasOwnProperty(item)) {
                    this[item] = args[item];
                }
            }
        },
        handleNextPage: function (args) {
            let emitType = _get(args, 'emitType');
            this.currentStep = _get(args, 'nextStep', '');   // initial or detail or confirm or result

            if (emitType === 'cancel') {
                this.alertData = {alertStatus: false};
                return; // if cancel is clicked, do not initialize fields

            } else if (this.currentStep === 'initial') {
                // user clicks pay another in payment results
                this.initializeAllDataFields();
            } else {
                this.initializeDataFieldsForStep(this.currentStep);    // hit continue on initial page and going to detail
                this.setPassedInFields(args);
            }
        },
        postPayment: async function () {    // emit event from confirm page
            let resultObj = {
                nextStep: 'result',
                paymentResultMessageType: 'info',
            };

            this.isProcessingPayment = true;

            const dataToAxios = {
                payments: {
                    billingAccountNumber: this.billingAccountNumber,
                    customerTkn: this.customerTkn,
                    billingPkgTkn: this.billingPkgTkn,
                    paymentAmount: parseFloat(this.paymentAmount),
                    opaqueData: this.opaqueData,
                },
                paymentConfirmationEmail: {
                    emailAddress: this.email || null,
                    languages: this.$i.language || 'en',
                }
            };

            try {
                await this.$datadog.dropLog(`Acct#: ${this.billingAccountNumber} confirmed payment - starting payment process`, 'card-quick-pay-bill');

                const quickPayBillDetail = await axios.post(`/api/QuickPayBill/PostPayments`, dataToAxios);
                let quickPayBillResults = _get(quickPayBillDetail, 'data.data');

                if (!quickPayBillResults) {
                    throw new Error(`${this.i.s.quickPayBillResult.errPaymentProcess}`);
                }

                let paymentConfirmationNumber = quickPayBillResults.authNetTransactionId;
                await this.$datadog.dropLog(`Acct#: ${this.billingAccountNumber} successfully completed payment. Amount:${this.paymentAmount}, confirmationId:${paymentConfirmationNumber}`, 'card-quick-pay-bill');

                resultObj.confirmationId = paymentConfirmationNumber || null;
                resultObj.messageProperty = 'successArrangePymt';

            } catch (err) {
                resultObj.paymentResultMessageType = 'error'
                resultObj.messageProperty = 'errPaymentProcess';
                if (_get(err, 'response.data.data')) {
                    let detail = Object.values(err.response.data.data)[0].error || null;

                    if (detail && detail.includes('Error Code:')) {
                        let authNetError = detail.split(":");
                        if (authNetError[1].includes('Error Code: 11 ')) {
                            resultObj.messageProperty = 'errDuplicateTransaction';
                        } else {
                            resultObj.authNetMessage = `${authNetError[2]}`;
                            resultObj.messageProperty = 'errPleaseTryAgain';
                        }
                    }
                }
                await this.$datadog.dropLog(`ERROR at postPayment: Acct#: ${this.billingAccountNumber}, ${_get(err, 'response.data.data') || _get(err, 'message')}`, 'card-quick-pay-bill');
            }

            this.isProcessingPayment = false;
            this.handleNextPage(resultObj);
        }
    }
};
