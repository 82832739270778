import axios from 'axios';

class DataDog {

    install(Vue){

        Vue.prototype.$datadog = {
            dropLog: async function (log, action) {
                try {
                    if (log && action) {
                        let postBody = {
                            log: log,
                            action: action
                        };
                        await axios.post(`/api/Datadog/dropLog`, postBody);
                    } else {
                        throw new Error(`missing data: log: ${log} action: ${action}`);
                    }
                } catch (e) {
                    console.error('error in this.$datadog.dropLog', e);
                }
            }
        }
    };
}

export default new DataDog();
