import axios from 'axios';
import cardTemplate from '../card-template/template.vue';
import { mask } from 'vue-the-mask';
import {v4 as uuid} from "uuid";

const BILLING_PACKAGE_TKN = 'billingPkgTkn';
const CUSTOMER_TKN = 'customerTkn';
const FAIL = 'fail';
const TEN = 10;

export default {
    components: {
        'cardTemplate': cardTemplate,
    },
    directives: {
        mask
    },
    data() {
        return {
            formData: {
                newPassword: null,
                confirmPassword: null,
                email: null,
                phone: null,
                myAccountInvite_id: null,
                billPackage: [],
            },
            masks:{
                phone:'###-###-####',
                // billingPkgTkn:'###',
                // customerTkn:'########',
            },
            rules:{
                emailRules: this.$rules.emailRules(this.$i),
                passwordRules: this.$rules.passwordRules(this.$i),
                phoneRules: this.$rules.phoneRules(this.$i),
                confirmPasswordRules: [
                    value => !! value|| this.$i.s.ruleErrors.errPasswordConfirmRequired,
                    value => {
                    const value2 = this.formData.newPassword;
                    return value2 === value || this.$i.s.ruleErrors.errPasswordMatch
                }],
                customerTkn: this.$rules.customerTkn(this.$i),
                billingPkgTkn: this.$rules.billingPkgTkn(this.$i),
            },
            alertData: {
                alertStatus: false,
                alertType: 'error',
                alertMsg: '',
                showResetPasswordButton: false
            },
            pathLinks: {
                logo: new URL('../../images/logo-padded@0.5x.png', import.meta.url).href,
            },
            dataToAxios: {
                contractCustomer_id: null,
                userProfile_id: null,
                items: []
            },
            isCodeAvail: false,
            isFormValid:false,
            isInternalFormValid:false,
        }
    },
    mounted() {
        if (this.$route.query.code) {
            this.isCodeAvail = true;
            this.lookupCode();
            return;
        }
        this.addTokenItem(null, false);
        this.isCodeAvail = false;
    },
    methods: {
        showAlert(alertStatus, alertType, alertMsg, showResetPasswordButton = false) {
            this.alertData.alertStatus = alertStatus;
            this.alertData.alertType = alertType;
            this.alertData.alertMsg = alertMsg;
            this.alertData.showResetPasswordButton = showResetPasswordButton;
            this.$refs.signupForm.resetValidation();
        },
        lookupCode: function () {
            axios.get(`/api/MyAccountInvite/${this.$route.query.code}/lookupItems`)
                .then((response) => {
                    if (!response || !response.data.success) throw new Error(response.error);
                    return response.data.data;
                })
                .then((validData) => {
                    this.formData.email = validData.EmailAddress;
                    this.formData.myAccountInvite_id = validData.MyAccountInvite_id;
                    for(let item of validData.items){
                        this.addTokenItem(item, true)
                    }
                })
                .catch(error=> {
                    this.showAlert(true, 'error', 'manualSignup.errSignupCode');
                })
        },
        addTokenItem(billPkg, isDisabled = false) {
            this.formData.billPackage.push({
                id: uuid(),
                billingPkgTkn: billPkg ? billPkg.EsgBillingPkgTkn : null,
                customerTkn: billPkg ? billPkg.EsgCustomerTkn : null,
                contractCustomer_id:billPkg ? billPkg.ContractCustomer_id : null,
                isDisabled: isDisabled
            });

        },
        deleteTokenItem(index) {
            if (this.formData.billPackage.length < 2) {
                this.showAlert(true, 'error', 'manualSignup.errCantDelete');
                return;
            }
            this.formData.billPackage.splice(index, 1)
        },
        signup: function () {
            if(!this.$refs.signupForm.validate(true))return;
            if(this.formData.phone && this.formData.phone.length < TEN ) return;

            let billingAct = [];
            for (let item of this.formData.billPackage) {
                const {id, isDisabled, ...rest} = item;
                billingAct.push(rest)
            }

            this.dataToAxios = {
                code: this.$route.query.code,
                email: this.formData.email,
                password: this.formData.confirmPassword,
                mobileNumber: this.formData.phone.split("-").join(""),
                myAccountInvite_id: this.formData.myAccountInvite_id,
                items: billingAct
            };


            return axios.post('/api/signup/', this.dataToAxios)
                .then((loginInfo) => {
                    if (!loginInfo) {
                        this.showAlert(true, 'error', 'manualSignup.errSignup');
                        return;
                    }

                    this.showAlert(
                        true, 'info',
                        this.isCodeAvail ? 'manualSignup.successSignup' : 'manualSignup.successEmailSent'
                    );
                    setTimeout(() => {
                        this.$router.replace({name:'login'});
                    }, 2000);
                })

                .catch(err => {
                    let errString = err?.response?.data?.message === 'Missing cbps' ? 'manualSignup.errMissingCbp' : 'manualSignup.errSignup';
                    let showResetPasswordButton = false;
                    if (err?.response?.data?.message?.includes('duplicateCbps')) {
                        const dups = err?.response?.data?.message.replace('duplicateCbps: ','')?.split(',');
                        errString = dups.map(dup => {
                            const [cbp, emailAddress] = dup.split(': ');
                            return {
                                billingAccountNumber: cbp,
                                emailAddress: emailAddress?.split('|').join(', ')
                            };
                        });

                        // errString = `${dupsErrors.join(', and ')} ${this.$i.s.manualSignup.errDuplicateCbps2}`;
                        showResetPasswordButton = true;
                    }
                    this.showAlert(true, 'error', errString, showResetPasswordButton);
                    throw new Error(err);
                })

        }
    }
};
