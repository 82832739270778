import axios from 'axios';
import cardTemplate from '../card-template/template.vue';

export default {
    components:{
        'cardTemplate': cardTemplate
    },
    data() {
        return {
            formData:{
                newPassword: null,
                confirmPassword: null
            },
            rules:{
                passwordRules:this.$rules.passwordRules(this.$i),
                confirmPasswordRules: [
                    value => !! value|| this.$i.s.ruleErrors.errPasswordConfirmRequired,
                    value => {
                        const value2 = this.formData.newPassword;
                        return value2 === value || this.$i.s.ruleErrors.errPasswordMatch
                    }],
            },
            alertData:{
                alertStatus:false,
                alertType:'error',
                alertMsg:'',
            },
            dataToAxios:null,
            isFormValid:false,
            isResetPasswordFailed:false,
        };
    },
    methods: {
        showAlert(alertStatus, alertType, alertMsg){
            this.alertData.alertStatus = alertStatus;
            this.alertData.alertType = alertType;
            this.alertData.alertMsg = alertMsg;
            this.$refs.resetForm.resetValidation();
        },
        changePassword: function () {
            this.isResetPasswordFailed = false;
            if(!this.$refs.resetForm.validate()) return;

            this.dataToAxios ={
                verificationCode: this.$route.query.verificationCode,
                newPassword: this.formData.newPassword
            };

            return axios.put('/api/UserProfile/ResetPassword/', this.dataToAxios)
                .then((loginInfo)=>{
                    if(!loginInfo.data.data.passwordReset){
                        this.showAlert(true, 'error', 'resetPassword.errPasswordReset');
                        this.$refs.resetForm.reset();
                        return;
                    }
                    this.showAlert(true, 'info', 'resetPassword.successPasswordReset');
                    this.$refs.resetForm.reset();
                    setTimeout(()=>{
                        this.$router.replace({name:'login'});
                    },2000);
                })
                .catch(err=>{
                    this.isResetPasswordFailed = true;
                    let details = err.response.data.message || '';
                    if(!details.length) {
                        details = 'resetPassword.errPasswordReset'
                    } else {
                        details = 'resetPassword.errLinkExpired'
                    }
                    this.showAlert(true, 'error', details);
                    throw new Error(err);
                })
        }
    }
};
