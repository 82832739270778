export default {
    props: {
        currentStep:{
            type: String,
            default: ''
        },
        billingAccountNumber: {
            type: String,
            default: ''
        },
        paymentAmount: {
            type: Number,
            default: 0
        },
        payFrom: {
            type: String,
            default: ''
        },
        sendConfirmationEmailTo: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
        };
    },
    computed: {
        formattedAmount: function () {
            if (this.paymentAmount !== undefined) return new Intl.NumberFormat("us-US", {
                style: "currency",
                currency: "USD"
            }).format(this.paymentAmount);
        },
        isReady: function(){
            return this.currentStep === 'confirm';
        },
        showEmailAddress: function(){
            return !!(this.sendConfirmationEmailTo && this.sendConfirmationEmailTo.length);
        }
    },
    methods: {
        cancel:function(){
            this.$emit('next', {
                emitType: 'cancel',
                nextStep: 'detail'
            })
        },
        confirmPay: function () {
            this.$emit('paymentConfirmed');
        },
    },
};
