import axios from 'axios';
import cardTemplate from '../card-template/template.vue';

export default {
    components: {
        'cardTemplate': cardTemplate
    },
    data() {
        return {
            textToDisplay: null,
            dataToAxios: null,
        };
    },
    mounted() {
        this.textToDisplay = this.$i.s.verifyEmail.loading;
        this.matchEmail(this.$route.query.email, this.$route.query.verificationCode);
    },

    methods: {
        matchEmail: async function (queryEmail, queryVCode) {
            this.dataToAxios = {
                emailAddress: queryEmail,
                verificationCode: queryVCode
            };

            try {
                const response = await axios.put('/api/UserProfile/VerifyEmailChange', this.dataToAxios);
                this.textToDisplay = response.data.data.isChangeEmailVerified? this.$i.s.verifyEmail.successVerifyChange : this.$i.s.verifyEmail.failedVerify ;
            } catch (err) {
                this.textToDisplay =  this.$i.s.verifyEmail.failedVerify ;
            }
        }
    }
};
