import VueRouter from 'vue-router';

import login from './components/card-login/template.vue';
import maintenance from './components/card-maintenance/template.vue';
import resetPwd from './components/card-reset-password/template.vue';
import resetPwdReq from './components/card-reset-password-request/template.vue';
import resetPwdReqSent from './components/card-reset-password-email-sent/template.vue';
import verifyEmail from './components/card-verify-email/template.vue';
import verifyEmailChange from './components/card-verify-email-change/template.vue';
import manualSignup from './components/card-manual-signup/template.vue';
import quickPayBill from './components/card-quick-pay-bill/template.vue';

export default new VueRouter({
    routes: [

        // url path
        // ==== To Turn on Maintenance Mode Uncomment From Here ====
        // {
        //     path: '/*',
        //     component: maintenance,
        // },
        // ==== END ====

        {
            path: '/login',
            component: login,
            name: "login",
        },
        {
            path: '/reset-password',
            component: resetPwd,
            name: "resetPassword",
        },
        {
            path: '/reset-password-request',
            component: resetPwdReq,
            name: "resetPwdReq"
        },
        {
            path: '/reset-password-email-sent',
            component: resetPwdReqSent,
            name: "resetPwdReqSent"
        },
        {
            path: '/verify-email',
            component: verifyEmail,
            name: "verifyEmail"
        },
        {
            path: '/verify-email-change',
            component: verifyEmailChange,
            name: "verifyEmailChange"
        },
        {
            path: '/signup',
            component: manualSignup,
            name: "manualSignup"
        },
        {
            path: '/quickpay',
            component: quickPayBill,
            name: "quickPayBill"
        },
        {
            path: '/*',
            redirect: '/login'
        },
    ],

})
