export default {
    props: {
        currentStep: {
            type: String,
            default: ''
        },
        resultType: {
            type: String,
            default: 'info'
        },
        billingAccountNumber: {
            type: String,
            default: ''
        },
        paymentConfirmationNumber: {
            type: String,
            default: ''
        },
        authNetMessage: {
            type: String,
            default: ''
        },
        messageProperty: {
            type: String,
            default: ''
        },
        confirmationEmail: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            errorSetting: {
                fontColor: 'accent--text',
                iconName: 'warning',
                iconColor: 'accent',
            },
            infoSetting: {
                fontColor: 'primary--text',
                iconName: 'celebration',
                iconColor: 'primary',
            },
        }
    },
    computed: {
        isReady: function () {
            return this.currentStep === 'result';
        },
        isInfo: function () {
            return this.resultType === 'info';
        },
        colors: function () {
            return this.isInfo ? this.infoSetting : this.errorSetting;
        },
        showPaymentConfirmationNumberFields: function () {
            return this.paymentConfirmationNumber && this.paymentConfirmationNumber.length > 0;
        },
        showEmailFields: function () {
            return this.confirmationEmail && this.confirmationEmail.length;
        },
    },
    methods: {
        payAnother: function () {
            this.$emit('next', {
                emitType: 'next',
                nextStep: 'initial',
            })
        },
    },
};
