import axios from 'axios';

export default {
    // props:['showSecondCard', 'showLogo','cardVertical'],
    props: {
        isLoginScreen: {
            type: Boolean,
            default: false
        },
        showSecondCard: {
            type: Boolean,
            default: false
        },
        showLogo: {
            type: Boolean,
            default: false
        },
        cardVertical: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            pathLinks: {
                /* bgImage: 'https://storage.googleapis.com/apge-mission-control/background-images/background001.jpeg', */
                bgImage: new URL('../../images/login_background.jpg', import.meta.url).href,
                enroll: null,
                logo: new URL('../../images/logo-padded@0.5x.png', import.meta.url).href,
            }
        };
    },
    async mounted() {
        await this.getEnrollmentRoute();
    },
    methods: {
        async getEnrollmentRoute() {
            this.pathLinks.enroll = (await axios.get('/api/enrollUrl')).data.data.enrollUrl + 'enroll/start';
        }
    },
};
