import {render} from 'micromustache';
import get from 'lodash/get';

class ApgeLangs {

    install(Vue, options){

        Vue.prototype.$i = new Vue({
            data:{
                language: options.language,
                languages: options.languages
            },
            computed:{
                s:function () {
                    return this.languages[this.language];
                },
            },
            methods:{
                t(name,data){
                    if(!name){
                        return '';
                    }
                    // console.log(name);
                    let template = get(this.languages[this.language], name, name);
                    // console.log(template);

                    return render(template,data);
                }
            }
        });
    };
}

export default new ApgeLangs();


