import moment from 'moment';
const dt = new Date();
const year = dt.getFullYear().toString().substring(2);
const mmyy = moment().endOf('month');

export default {
    emailRules:function($i){
        return [
            (value) => !!value ||$i.s.ruleErrors.errEmailRequired,
            (value) => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) ||$i.s.ruleErrors.errEmailInvalid,
        ]
    },
    passwordRules: function($i){
        return [
            (value) => !!value || $i.s.ruleErrors.errPasswordRequired,
            (value) => /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,24}$/.test(value) || $i.s.ruleErrors.errPasswordRule,
        ]
    },
    confirmPasswordRules:[
        // this has to be in the index.js file due to one input not having scope for both passwords simultaneously
    ],
    phoneRules:function($i){
        return [
            (value) => !!value ||$i.s.ruleErrors.errPhoneRequired,
            (value) => /^\d{3}-\d{3}-\d{4}$/.test(value) ||$i.s.ruleErrors.errPhoneInvalid,
        ]
    },
    customerTkn:function($i){
        return [
            (value) => !!value ||$i.s.ruleErrors.errRequired,
            (value) => parseInt(value) > 0 ||$i.s.ruleErrors.errNumberOnly,
            (value) => /^[0-9]{7,8}$/.test(value) ||$i.s.ruleErrors.errDigits7or8,
        ]
    },
    billingPkgTkn:function($i){
        return [
            (value) => !!value ||$i.s.ruleErrors.errRequired,
            (value) => parseInt(value) > 0 ||$i.s.ruleErrors.errNumberOnly,
            (value) => /^[0-9]{2,3}$/.test(value) ||$i.s.ruleErrors.errDigits2or3,
        ]
    },
    itemRequiredRules:function($i){
        return [
            (value) => !!value ||$i.s.ruleErrors.errRequired,
        ]
    },
    zipCode:function($i){
        return [
            (value) => !!value ||$i.s.ruleErrors.errRequired,
            (value) => /^[0-9]{5}$/.test(value) ||$i.s.ruleErrors.errDigits5,
        ]
    },
    exp:function($i){
        return [
            (value) => /^[0-9]{2}\/[0-9]{2}$/.test(value) ||$i.s.ruleErrors.errFormat,
            (value) =>{
                const val = value && value.length > 2 ? parseInt(value.substring(0,2)):value;
                return val < 13 ||$i.s.ruleErrors.errMonth
            },
            (value) => {
                const yearVal = value && value.length > 3 ? value.substring(3):value;
                return yearVal >= parseInt(year) ||$i.s.ruleErrors.errYear
            },
            (value) => {
                if(value && value.length === 5){
                    let val = `20${value.substring(3)}-${value.substring(0,2)}-01`;
                    let lastDay = moment(val).endOf('month');
                    return moment(lastDay).isSameOrAfter(mmyy,"month") ||$i.s.ruleErrors.errInvalidExpiration
                } return true
            },
            (value) => !!value ||$i.s.ruleErrors.errRequired,
        ]
    },
    paymentAmount:function($i){
        return [
            (value) => /^[0-9]+(\.[0-9]{1,2})?$/.test(value) ||$i.s.ruleErrors.errInvalidAmount,
            (value) => !!value ||$i.s.ruleErrors.errRequired,
            (value) => value > 0 ||$i.s.ruleErrors.errGreaterThanZero,
        ]
    },
    creditCard:function($i){
        return [
            (value) =>{
                const val = value && value.length >4 ?value.split(" ").join(""):value;
                return /^[0-9]{16}$/.test(val) ||$i.s.ruleErrors.err16digits // 16 digits + 3 spaces
            },
            (value) => !!value ||$i.s.ruleErrors.errRequired,
        ]
    },
    cardHolder:function($i){
        return [
            (value) => value && value.length <= 64 ||$i.s.ruleErrors.err64Length,
            (value) => !!value ||$i.s.ruleErrors.errRequired,
        ]
    },
    cardCode:function($i){
        return [
            (value) => /^[0-9]{3}$/.test(value) ||$i.s.ruleErrors.errCardCode,
            (value) => !!value ||$i.s.ruleErrors.errRequired,
        ]
    },
    routingNo:function($i){
        return [
            (value) => /^[0-9]{9}$/.test(value) ||$i.s.ruleErrors.errRouting,
            (value) => !!value ||$i.s.ruleErrors.errRequired,
        ]
    },
    accountNo:function($i){
        return [
            (value) => /^[0-9]{4,17}$/.test(value) ||$i.s.ruleErrors.errAccNo,
            (value) => !!value ||$i.s.ruleErrors.errRequired,
        ]
    },
};
