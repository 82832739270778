import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import VueRouter from 'vue-router';

import App from './components/app/App.vue';
import router from './routes.js';
import ApgeLangs from '../common/ApgeLangs';
import rules from './rules.js';
import languages from './langs';
import DataDog from "../common/DataDog";


Vue.use(VueRouter);
Vue.use(Vuetify);
Vue.prototype.$rules = rules;

Vue.use(ApgeLangs,{
    language:'en',//locale,
    languages:languages
});
Vue.use(DataDog);

const vuetify = new Vuetify({
    theme: {
        themes: {
            light:{
                primary: '#0E385F',  //@apge-blue
                secondary: '#888888', //@apge-gray
                accent: '#F15C22',  //@apge-organge
            }
        },
    },
});

new Vue({
    el: '#app',
    router:router,
    vuetify: vuetify,
    render: h => h(App),
});

