export default {
    login: {
        headerText:"Bienvenido al portal de clientes de MyAccount de APG&E. ¡Puede administrar su cuenta y ver su uso aquí!",
        loginHelp:"¿Necesita ayuda para iniciar la sesión?",
        username:"Dirección de correo electrónico o nombre de usuario",
        password:"Contraseña",
        cancel:"Cancelar",
        clear:"Borrar",
        login:"Iniciar sesión",
        notACustomer:"¿No es cliente de APG&E?",
        click:"clic",
        here:"aquí",
        registerYourAccount:"Registre su cuenta con APG&E",
        cardFooter:"Para ver nuestras excelentes tarifas y nuestro servicio al cliente de primera clase.",
        errLogin:"Nombre de usuario o contraseña incorrecta.",
        errNotVerified:"La dirección de correo electrónico no está verificada",
        successLogin:"Inicio de sesión exitoso. Redireccionando a la página de inicio.",
        thankYou:"Gracias!",
        ok:"OK",
        verifyEmail: "Haga clic aquí para verificar su correo electrónico.",
    },
    manualSignup: {
        signupNoCode:"Gracias por elegir APG&E como su proveedor de energía. En este portal de MyAccount para clientes de APG&E, puede administrar su cuenta y ver su uso aquí.  Por favor ingrese la siguiente información. Puede encontrar el número de cuenta de facturación en su factura mensual.",
        signupWithCode:"Bienvenido al portal de clientes de MyAccount. ¡Puede administrar su cuenta y ver su uso aquí! Por favor ingrese la siguiente información.",
        email:"Dirección de correo electrónico",
        phone:"Número de teléfono",
        newPassword:"Nueva contraseña",
        confirmPassword:"Confirmar contraseña",
        billPkgList:"Números de cuenta de facturación",
        cancel:"Cancelar",
        signup:"Regístrase",
        errItemRequired:"Esta seccion es requerida",
        errSignupCode:"Parece que el URL de registro que le dieron no funciona. Vuelva a intentarlo y, si el problema persiste, llame a nuestro equipo de atención al cliente al 1-877-544-4857 o envíe un correo electrónico a customer@apge.com.",
        errSignup:"Error al registrarse Vuelva a intentarlo y, si el problema persiste, llame a nuestro equipo de atención al cliente al 1-877-544-4857 o envíe un correo electrónico a customer@apge.com.",
        errMissingCbp:"Hubo un problema con sus números de cuenta de facturación. Verifique los números e intente nuevamente. Si el problema persiste, llame a nuestro equipo de atención al cliente al 1-877-544-4857",
        errDuplicateCbps1:"El números de cuenta de facturación {{billingAccountNumber}} ya está asociado con la dirección de correo electrónico {{emailAddress}}. ",
        errDuplicateCbps2:"Si no puedes iniciar sesión, intenta restablecer tu contraseña desde el botón Restablecer la Contraseña. Si el problema persiste o necesitas ayuda adicional, llame a nuestro equipo de atención al cliente al 1-877-544-4857",
        errDigits7or8:"7 o 8 dígitos",
        errDigits2or3:"2 o 3 dígitos",
        errCantDelete:"No se puede eliminar este elemento. Debe haber al menos un número de cuenta de facturación.",
        successSignup:"¡Registro completo! Redireccionando a la página de inicio de sesión",
        successEmailSent:"Por favor revise su correo electrónico. Le enviamos un correo electrónico para verificar su dirección de correo electrónico.  Si su correo electrónico no llega, revise su carpeta de SPAM.",
    },
    resetPassword: {
        headerText:"Por favor elija una nueva contraseña:",
        passwordRule:"La contraseña debe tener al menos 8 caracteres, incluir un número, una letra minúscula y una letra mayúscula.",
        newPassword:"Nueva contraseña",
        confirmPassword:"Confirmar contraseña",
        changePassword:"Cambiar la contraseña",
        errPasswordReset:"La actualización de contraseña falló. Inténtelo de nuevo.",
        errPasswordConfirmRequired:"Confirmar contraseña",
        errPasswordMatch:"La nueva contraseña y la contraseña de confirmación no coinciden.",
        errLinkExpired:"El enlace proporcionado para restablecer su contraseña no es válido o ha expirado. ",
        clickHere:"Haga clic aquí para solicite uno nuevo.",
        successPasswordReset:"La contraseña se actualizó correctamente.",
    },
    resetPasswordEmailSent: {
        headerText:"Se ha enviado un correo electrónico con instrucciones para restablecer la contraseña a la dirección de correo electrónico proporcionada.",
        linkLimits:"Para su seguridad, este enlace estará activo durante 1 hora. Si su correo electrónico no llega, revise su carpeta de SPAM.",
        returnToLogin:"Regresar al inicio de sesión"
    },
    resetPasswordRequest: {
        headerText:"Ingrese la dirección de correo electrónico que utilizó para procesar el registro.",
        cancel:"Cancelar",
        submit:"Submitir",
        email:"Dirección de correo electrónico",
        resetPassword: "Restablecer la Contraseña",
        footerText:"¿No recuerda la información de su cuenta? Llame a nuestro equipo de atención al cliente al 1-877-544-4857 o envíe un correo electrónico a customer@apge.com.",
    },
    verifyEmail: {
        successEmailSent:"Por favor revise su correo electrónico. Le enviamos un correo electrónico para verificar su dirección de correo electrónico. Si su correo electrónico no llega, revise su carpeta de SPAM.",
        successVerify:"Correo electrónico verificado. Redireccionando a la página de inicio de sesión.",
        errVerify:"¡Lo siento! Su código de verificación parece no es válido. Por favor solicite uno nuevo. Redireccionando a la página de inicio de sesión.",
        errSomethingWrong:"Algo falló mal. Si el problema persiste, llame a nuestro equipo de atención al cliente al 1-877-544-4857 o envíe un correo electrónico a customer@apge.com.",
        loading:"Cargando página...",
        failedVerify:"La verificación de correo electrónico falló.. llame a nuestro equipo de atención al cliente al 1-877-544-4857 o envíe un correo electrónico a customer@apge.com.",
        successVerifyChange:"La verificación de correo electrónico se realizó con éxito. Por favor, actualiza tu página de inicio de sesión para ver la actualización.",
    },
    ruleErrors: {
        errEmailRequired:"Se requiere su dirección de correo electrónico.",
        errEmailInvalid:"La dirección de correo electrónico no válida.",
        errPasswordRequired:"Se requiere contraseña.",
        errPasswordRule:"Debe tener al menos 8 caracteres, incluir un número, una letra minúscula y una letra mayúscula.",
        errPasswordConfirmRequired:"Confirmar contraseña",
        errPasswordMatch:"La nueva contraseña y la contraseña de confirmación no coinciden.",
        errPhoneRequired:"Se requiere número de teléfono.",
        errPhoneInvalid:"Numero de telefono invalido.",
        errItemRequired:"Esta seccion es requerida.",
        errNumberOnly:"Números inválidos",
        errDigits7or8:"7 o 8 dígitos",
        errDigits2or3:"2 o 3 dígitos",
        errDigits5:"5 dígitos",
        err16digits:"16 dígitos",
        err5digits:"5 dígitos",
        errCardCode:"3 dígitos",
        errRouting:"9 dígitos",
        errAccNo:"4 o 17 dígitos",
        errFormat:"Formato inválido",
        errMonth:"Mes inválido",
        errYear:"Año no válido",
        err22Length:"Máximo 22 caracteres",
        err64Length:"Máximo 64 caracteres",
        errRequired:"Requerida.",
        errInvalidAmount:"Monto invalido",
        errInvalidExpiration:"Fecha de expiracion inválida",
        errGreaterThanZero:"La cantidad debe ser mayor que cero",
    },
    quickPayBillInitial: {
        headerTitle:"Realizar un pago Rápido",
        headerText:"Ingrese su número de cuenta de facturación y el código postal del servicio para realizar un pago.",
        zipCode:"Código postal de Servicio",
        checkAmountDue:"Verifique Monto Debido",
        billingAccountNumber:"Para el número de cuenta",
        goToLoginPage:"Ir a la página de inicio de sesión",
        billingAccountNumberLocation:"El número de cuenta de facturación se encuentra en la primera página de su factura mensual en la esquina superior derecha",
        cancel:"Cancelar",
        accountUnderPOR:"APG&E no puede aceptar su pago directamente. Todos los pagos deben hacerse directamente con su empresa de servicios públicos. Comuníquese con su compañía de servicios públicos para realizar un pago.",
        errNoBillingAccountNumberFound:"No pudimos encontrar el número de cuenta de facturación con el código postal proporcionado. Verifique los números y vuelva a intentarlo. Si el problema persiste, llame a nuestro equipo de atención al cliente al 1-877-544-4857 o envíe un correo electrónico a customer@apge.com",
        errNoAmountDueFound:"No pudimos recuperar los detalles del monto adeudado del número de cuenta de facturación. Actualice la pantalla y vuelva a intentarlo. Si el problema persiste, llame a nuestro equipo de atención al cliente al 1-877-544-4857 o envíe un correo electrónico a customer@apge.com.",
        errReCaptchaNotChecked:"Haga clic en la casilla de verificación",
        errReCaptchaExpired:"ReCAPTCHA la sesión se ha expirado. Vuelva a hacer clic en la casilla de verificación.",
        errReCaptchaError:"ReCAPTCHA la sesión ha producido un error. Por favor, actualice y Inténte de nuevo.",
        errPleaseTryAgain:"Vuelva a intentarlo. Si el problema persiste, comuníquese con nuestro equipo de atención al cliente al 1-877-544-4857 o envíe un correo electrónico a customer@apge.com.",
        processingPayment:"Procesarando su pago",
    },
    quickPayBillDetail: {
        headerText:"Por favor, ingrese su información de pago para continuar",
        billingAccountNumber:"Para el número de cuenta: ",
        amountDue:"Monto Debido: ",
        chooseAmount:"Cantidad de pago: ",
        cardNumber:"Número de tarjeta",
        exp:"Fecha de expiracion",
        cardCode:"CVV",
        zipcode:"Código postal",
        continue:"Seguir",
        fullName:"Nombre del titular de la tarjeta",
        bankAcctNo:"Número de cuenta",
        bankRoutingNo:"Número de ruta (9 dígitos)",
        businessCheckingCheckbox: "Cuenta Bancaria Comercial",
        errInvalidRoutingNo:"El número de ruta no es válido",
        errCheckingNotAllowed:"La cuenta bancaria no está permitida para este usuario",
        nameOnAccount:"Nombre en la cuenta",
        bankAccount:"Cuenta bancaria",
        creditCard:"Tarjeta de crédito",
        hintExp:"MM/AA",
        errCreditCardType:"Tipo de tarjeta de credito invalida. Aceptamos VISA, MasterCard y Discover.",
        sendConfirmationEmail:"Confirmación de pago por correo electrónico (opcional)",
        emailAddress:"Correo electrónico",
    },
    quickPayBillConfirm:{
        headerText:"Confirme los detalles del pago a continuación",
        paymentAmount:"Cantidad de pago: ",
        payFrom:"Pagar con: ",
        creditCardEnding:"Tarjeta de Crédito/Débito que termina en",
        bankAccountEnding:"Cuenta bancaria terminada en",
        payToBillingAccount:"El número de cuenta que desea pagar:" ,
        sendConfirmationEmailTo:"El número de confirmación de pago se enviará por correo electrónico a: " ,
        yes:"Pagar",
        cancel:"Cancelar",
    },
    quickPayBillResult:{
        billingAccountNumber:"Para el número de cuenta: ",
        paymentConfirmationNumber:"Número de confirmación de pago: ",
        successArrangePymt:"¡Hemos programado su pago! Se reflejará en su cuenta una vez que se complete su transacción.",
        successArrangePymtEmailWillBeSent:"El número de confirmación de pago se enviará por correo electrónico a {{emailAddress}}.",
        successArrangePymtThankYou:"Gracias por su pago.",
        errPaymentProcess:"Algo falló al procesar su pago. Vuelva a intentarlo. Si el problema persiste, comuníquese con nuestro equipo de atención al cliente al 1-877-544-4857 o envíe un correo electrónico a customer@apge.com.",
        errDuplicateTransaction: "Parece que ya ha enviado un pago por esa cantidad. Si esto es intencional, espere 2 minutos e intente nuevamente.",
        errPleaseTryAgain:"Vuelva a intentarlo. Si el problema persiste, comuníquese con nuestro equipo de atención al cliente al 1-877-544-4857 o envíe un correo electrónico a customer@apge.com.",
        makeAnotherPayment:"Pagar otro",
        done:"Terminada",
    },
    maintenance:{
        message:"Gracias por visitarnos! Por motivos de mantenimiento en nuestro sistema, estaremos fuera de línea hasta el Domingo a las 4pm CST. Nuestras más sinceras disculpas por esta interrupción.",
        email:"Correo Electrónico",
        phone:"Teléfono",
        hours:"Horario (CST)",
        ourEmail:"customer@apge.com",
        ourPhone:"1.877.544.4857",
        ourHoursWeekday:"8:00AM - 7:00PM",
        ourHoursFriday:"8:00AM - 5:00PM",
        ourHoursSaturday:"9:00AM - 1:00PM",
        ourHoursSunday:"Cerrado los Domingos",
    },
};

