import cardTemplate from '../card-template/template.vue';

export default {
    components:{
        'cardTemplate': cardTemplate
    },
    data() {
        return {
        };
    }
};