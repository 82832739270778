import { VueRecaptcha } from 'vue-recaptcha';
import axios from 'axios';
import {get as _get, has as _has} from 'lodash';

export default {
    props: {
        currentStep: {
            type: String,
            default: ''
        },
        customerTknOld: {
            type: String,
            default: ''
        },
        billingPkgTknOld: {
            type: String,
            default: ''
        },
        zipCodeOld: {
            type: String,
            default: ''
        },
    },
    components: {
        VueRecaptcha,
    },
    data() {
        return {
            showTooltips: false,
            customerTkn: null,
            billingPkgTkn: null,
            zipCode: null,
            rules: {
                customerTkn: this.$rules.customerTkn(this.$i),
                billingPkgTkn: this.$rules.billingPkgTkn(this.$i),
                zipCode: this.$rules.zipCode(this.$i),
            },
            isPOR: false,
            siteKey: '6Le9l5IdAAAAAJ282ENZoTTYD7NyKFXzHQoSYJ16',
            captcha:null,
        }
    },
    mounted: function (){
        this.initializeFields();
    },
    computed: {
        isReady: function () {
            return this.currentStep === 'initial';
        },
    },
    methods: {
        verifyReCaptcha: function(reCaptchaRes){
            this.captcha = reCaptchaRes;
            // console.log('recaptcha validation', this.captcha);
            return this.$emit('resetAlert', {
                alertStatus: false
            });
        },
        handleErrorReCaptcha: function(type){
            // console.log('recaptcha error')
            return this.$emit('error', {
                    alertStatus: true,
                    alertType: 'error',
                    alertMsg: type === 'error' ? 'quickPayBillInitial.errReCaptchaError' : 'quickPayBillInitial.errReCaptchaExpired'
            });
        },
        initializeFields: function () {
            for (let propField of ['customerTknOld', 'billingPkgTknOld','zipCodeOld']) {
                let newField = propField.slice(0,-3);
                this[newField] = this[propField] ? this[propField] : '';
            }
            if (_has(this.$refs,'recaptcha')) {
                this.$refs.recaptcha.reset();
            }
            this.captcha = '';
        },
        checkAmountDue: async function () {
            if (!this.$refs.quickPayBill.validate()) return;
            let alertObj = {
                alertStatus: true,
                alertType: 'error',
                alertMsg: ''
            };

            // captcha
            if (!this.captcha){
                alertObj.alertMsg = 'quickPayBillInitial.errReCaptchaNotChecked';
                return this.$emit('error', alertObj);
            }

            // getData
            let getAmountData = await this.getBalanceData();

            // captcha error handling
            if (_has(getAmountData,'isError')) {
                alertObj.alertMsg = getAmountData.message;
                return this.$emit('error', alertObj);
            }
            // get
            let obj = {
                emitType: 'next',
                nextStep: 'detail',
                customerTkn: this.customerTkn,
                billingPkgTkn: this.billingPkgTkn,
                zipCode: this.zipCode,
                isPOR: getAmountData.isPOR,
                isCheckingAllowed: getAmountData.isCheckingAllowed,
                balance: getAmountData.Total_Balance,
                email: getAmountData.email,
                apgeAccountNo: getAmountData.Account_Number,
            };

            // console.log('get', obj);

            this.$emit('next', obj);
        },
        getBalanceData: async function () {
            let foundBillingAccountNumber;
            try {
                foundBillingAccountNumber = await axios.post('/api/QuickPayBill/LookupAccount', {
                    customerTkn: this.customerTkn,
                    billingPkgTkn: this.billingPkgTkn,
                    zipCode: this.zipCode,
                    reCaptcha: this.captcha
                });
                if (_get(foundBillingAccountNumber, 'data.message')) {
                    throw new Error(_get(foundBillingAccountNumber, 'message'));
                }
                let gotBalance = _get(foundBillingAccountNumber, 'data.data');

                if (!gotBalance) {
                    throw new Error('quickPayBillInitial.errNoBillingAccountNumberFound');

                } else if (_get(gotBalance, 'isPOR')) {
                    throw new Error('quickPayBillInitial.accountUnderPOR');
                }
                return gotBalance;

            } catch (e) {
                let errorCode = _get(e, 'response.data.message');
                if (this.$i.s.quickPayBillInitial.hasOwnProperty(errorCode)) {
                    return {isError: true, message: `quickPayBillInitial.${errorCode}`};
                } else if (errorCode) {
                    return {isError: true, message: errorCode};
                } else {
                    return {isError: true, message: _get(e, 'message')};
                }
            }
        }
    }
};
