import axios from 'axios';
import cardTemplate from '../card-template/template.vue';

export default {
    components:{
        'cardTemplate': cardTemplate
    },
    data() {
        return {
            formData:{
                email: null
            },
            rules:{
                emailRules: this.$rules.emailRules(this.$i)
            },
            alertData:{
                alertStatus:false,
                alertType:'error',
                alertMsg:'',
            },
            isFormValid:false
        };
    },
    methods: {
       resetFormValidity(){
            for (const formName of Object.keys(this.$refs)){
                this.$refs[formName].resetValidation();
            }
        },
        checkFormValidity() {
            const formKeys = Object.keys(this.$refs);
            for (const key of formKeys) {
                this.isFormValid = !!this.$refs[key].valid;
                this.$refs[key].validate(true);
            }
            return this.isFormValid;
        },
        resetPassword: function () {
            if(!this.checkFormValidity()) return;


            return axios.post('/api/UserProfile/ResetPasswordRequest', {emailAddress: this.formData.email})
                .then(()=>{
                    this.$router.replace({name:'resetPwdReqSent'});
                })
                .catch(err=>{
                    this.$router.replace({name:'resetPwdReqSent'});
                    // throw new Error(err);
                })
        }
    }
};

